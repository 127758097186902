<template>
  <div>
    <v-divider class="my-5" />
    <label-icon label="Tipos de Fluxo" icon="bubble_chart" bold />
    <div v-if="hasFlowTypes">
      <v-list two-line>
        <v-list-group
          v-for="(ft, indexFt) in flowTypes"
          :key="indexFt"
          class="elevation-1"
        >
          <template v-slot:activator>
            <v-list-item class="pa-7">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{ ft.flowType.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <div v-if="ft.pointsOrder && ft.pointsOrder.length > 0">
            <h3 class="subtitle-1 black--text pa-3 font-weight-bold">Pares</h3>
            <property-value
              v-show="ft.affectedSpot"
              class="pa-3"
              property="Local afetado"
              :value="ft.affectedSpot"
            />
            <view-tracking-impaction-point
              v-for="po in ft.pointsOrder"
              :key="po.id"
              class="grey mb-2"
              :tracking-point="po.trackingPoint"
              :impaction-point="po.impactionPoint"
            />
          </div>
          <div v-else class="body-1 pa-3 grey--text">
            Nenhum par selecionado.
          </div>
          <v-divider />
          <div class="body-2">
            <div class="pa-3 black--text">
              <!--
               <text-editor
                :value="ft.instructions"
                label="Instruções ao paciente"
                return-value="instructions"
                class="mb-3"
                @save="changeProperties"
                @open="selectIndex(indexFt)"
              />
              -->

              <text-editor
                :value="ft.therapistComments"
                label="Comentários aos terapeutas"
                return-value="therapistComments"
                class="mb-3"
                @save="changeProperties"
                @open="selectIndex(indexFt)"
              />
              <text-editor
                :value="ft.patientComments"
                label="Comentários ao paciente"
                return-value="patientComments"
                class="mb-3"
                @save="changeProperties"
                @open="selectIndex(indexFt)"
              />
            </div>

            <v-row justify="center" class="py-5">
              <v-btn dark color="red" @click="removeFlowType(indexFt)">
                <v-icon class="mr-2">delete</v-icon>
                Remover Tipo de Fluxo
              </v-btn>
            </v-row>
          </div>
        </v-list-group>
      </v-list>
    </div>
    <div v-else class="mt-5">Nenhum tipo de fluxo adicionado.</div>
  </div>
</template>

<script>
export default {
  name: "FlowTypesSection",
  data: () => ({
    indexSelected: -1,
  }),
  computed: {
    flowTypes() {
      return this.$store.state.diagnosis.diagnose.flowTypes;
    },
    hasFlowTypes() {
      return this.$store.getters["diagnosis/hasFlowTypes"];
    },
  },
  methods: {
    removeFlowType(index) {
      this.$store.commit("diagnosis/removeFlowType", index);
    },
    selectIndex(index) {
      this.indexSelected = index;
    },
    changeProperties(value) {
      this.$store.commit("diagnosis/changePropertyFlowType", {
        index: this.indexSelected,
        property: value,
      });
    },
  },
};
</script>

<style scoped></style>
